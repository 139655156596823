export default {
  data: function data() {
    return {
      jobWeekDays: new Array(),
      jobDayOfMonths: new Array()
    };
  },
  methods: {
    updateWeekDays: function updateWeekDays() {
      this.jobWeekDays = [{
        id: 1,
        short_name: "Sun",
        long_name: "Sunday"
      }, {
        id: 2,
        short_name: "Mon",
        long_name: "Monday"
      }, {
        id: 3,
        short_name: "Tue",
        long_name: "Tuesday"
      }, {
        id: 4,
        short_name: "Wed",
        long_name: "Wednesday"
      }, {
        id: 5,
        short_name: "Thu",
        long_name: "Thursday"
      }, {
        id: 6,
        short_name: "Fri",
        long_name: "Friday"
      }, {
        id: 7,
        short_name: "Sat",
        long_name: "Saturday"
      }];
    },
    updateDayOfMonths: function updateDayOfMonths() {
      this.jobDayOfMonths = [{
        id: 1,
        value: "First"
      }, {
        id: 2,
        value: "Second"
      }, {
        id: 3,
        value: "Third"
      }, {
        id: 4,
        value: "Fourth"
      }, {
        id: 5,
        value: "Last"
      }];
    }
  },
  mounted: function mounted() {
    var _this = this;

    _this.updateWeekDays();

    _this.updateDayOfMonths();
  }
};