//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from "moment-timezone";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  data: function data() {
    return {
      rawTime: null,
      timePicker: false,
      time: null
    };
  },
  props: {
    defaultTime: {
      type: String,
      default: function _default() {
        return moment().format("hh:mm A");
      }
    },
    pageLoading: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: function _default() {
        return "Time";
      }
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    removeBorder: {
      type: Boolean,
      default: false
    },
    startTimePicker: {
      type: Boolean,
      default: false
    },
    endTimePicker: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateTime: function updateTime(rawTime) {
      var _this = this;

      if (this.startTimePicker && rawTime == "23:59") {
        rawTime = "00:00";
      }

      if (this.endTimePicker && rawTime == "00:00") {
        rawTime = "23:59";
      }

      this.$nextTick(function () {
        _this.rawTime = rawTime;

        _this.$refs.rawTime.save(rawTime);

        _this.time = rawTime ? moment(rawTime, ["HH:mm"]).format("hh:mm A") : null;

        _this.$emit("change", true);
      });
    }
  },
  mounted: function mounted() {
    this.time = this.defaultTime;
    this.rawTime = this.time ? moment(this.time, ["hh:mm A"]).format("HH:mm") : null;
  },
  watch: {
    time: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        this.$emit("update:time-picker", param);
        this.$emit("input", param);
      }
    }
  }
};