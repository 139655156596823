//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import RecurringScheduleHeader from "@/view/pages/job/partials/Recurring-Schedule-Header.vue";
import RecurringScheduleBody from "@/view/pages/job/partials/Recurring-Schedule-Body.vue";
import JobScheduleTeam from "@/view/pages/job/partials/Job-Schedule-Team.vue";
export default {
  name: "recurring-schedule",
  props: {
    showCalendar: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {};
  },
  components: {
    RecurringScheduleHeader: RecurringScheduleHeader,
    RecurringScheduleBody: RecurringScheduleBody,
    JobScheduleTeam: JobScheduleTeam
  }
};