import "core-js/modules/es.array.find-index.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { JobScheduleTeamEventBus } from "@/core/lib/job/job.schedule.team.lib";
export default {
  name: "job-schedule-team-body",
  data: function data() {
    return {
      availableTeam: [],
      team: {
        assigned_team: new Array(),
        email_team: 1
      }
    };
  },
  watch: {
    team: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        JobScheduleTeamEventBus.$emit("update:assigned-team", param);
      }
    }
  },
  methods: {
    getTeamUserName: function getTeamUserName(id) {
      var _this = this;

      var index = _this.lodash.findIndex(_this.availableTeam, function (data) {
        return data.id == id;
      });

      if (index >= 0) {
        return _this.availableTeam[index].display_name;
      }

      return "N/A";
    },
    getTeamDesignation: function getTeamDesignation(id) {
      var _this = this;

      var index = _this.lodash.findIndex(_this.availableTeam, function (data) {
        return data.id == id;
      });

      if (index >= 0) {
        if (_this.lodash.isEmpty(_this.availableTeam[index].designation) === false) {
          return _this.availableTeam[index].designation.value;
        }

        return "";
      }

      return "N/A";
    },
    getTeamUserProfile: function getTeamUserProfile(id) {
      var _this = this;

      var index = _this.lodash.findIndex(_this.availableTeam, function (data) {
        return data.id == id;
      });

      if (index >= 0) {
        return this.$assetAPIURL(_this.availableTeam[index].profile_logo);
      }

      return null;
    },
    removeAssignedTeam: function removeAssignedTeam(_ref) {
      var engineer = _ref.engineer,
          index = _ref.index;
      JobScheduleTeamEventBus.$emit("remove:schedule-team", {
        engineer: engineer,
        index: index
      });
    }
  },
  beforeDestroy: function beforeDestroy() {
    /*JobScheduleTeamEventBus.$off("update:schedule-team");*/
  },
  created: function created() {
    var _this = this;

    JobScheduleTeamEventBus.$on("update:schedule-team", function (_ref2) {
      var availableTeam = _ref2.availableTeam,
          assignedTeam = _ref2.assignedTeam;
      _this.availableTeam = availableTeam;
      _this.team.assigned_team = assignedTeam;
    });
  }
};