import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from "moment-timezone";
import AppConfiguration from "@/core/config/app.config";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { JobEventBus } from "@/core/lib/job/job.lib";
import { JobScheduleTeamEventBus } from "@/core/lib/job/job.schedule.team.lib";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  name: "one-off-schedule-body",
  mixins: [ValidationMixin],
  props: {
    showCalendar: {
      type: Boolean,
      required: true
    },
    internal: {
      type: Boolean,
      default: false
    },
    dailyRecurrence: {
      type: Object,
      default: function _default() {
        return new Object();
      }
    },
    monthlyRecurrence: {
      type: Object,
      default: function _default() {
        return new Object();
      }
    },
    weeklyRecurrence: {
      type: Object,
      default: function _default() {
        return new Object();
      }
    }
  },
  data: function data() {
    return {
      schedule: {
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null
      },
      schedule_later: 0,
      startDatePicker: false,
      endDatePicker: false,
      rawStartTime: null,
      startTimePicker: false,
      rawEndTime: null,
      endTimePicker: false,
      scheduleString: {
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null
      }
    };
  },
  watch: {
    schedule: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        this.doActions(param);
      }
    },
    schedule_later: function schedule_later(param) {
      JobScheduleTeamEventBus.$emit("update:schedule-later", !!+param);
    }
  },
  methods: {
    updateStartTimeAMPM: function updateStartTimeAMPM(param) {
      var startTime = this.lodash.toString(this.schedule.start_time);

      if (startTime) {
        if (param == "am") {
          startTime = this.lodash.replace(startTime, "PM", "AM");
        } else {
          startTime = this.lodash.replace(startTime, "AM", "PM");
        }

        this.updateStartTime(moment(startTime, ["hh:mm A"]).format("HH:mm"));
      }
    },
    updateEndTimeAMPM: function updateEndTimeAMPM(param) {
      var endTime = this.lodash.toString(this.schedule.end_time);

      if (endTime) {
        if (param == "am") {
          endTime = this.lodash.replace(endTime, "PM", "AM");
        } else {
          endTime = this.lodash.replace(endTime, "AM", "PM");
        }

        this.updateEndTime(moment(endTime, ["hh:mm A"]).format("HH:mm"));
      }
    },
    doActions: function doActions(param) {
      this.scheduleString.start_date = null;
      this.scheduleString.end_date = null;
      this.scheduleString.start_time = null;
      this.scheduleString.end_time = null;
      JobEventBus.$emit("update:one-off-schedule", param);
      JobScheduleTeamEventBus.$emit("get:schedule-team", param);
    },
    updateStartTime: function updateStartTime(rawTime) {
      this.$refs.rawStartTime.save(rawTime);
      this.schedule.start_time = moment(rawTime, ["HH:mm"]).format("h:mm A");
    },
    updateEndTime: function updateEndTime(rawTime) {
      this.$refs.rawEndTime.save(rawTime);
      this.schedule.end_time = moment(rawTime, ["HH:mm"]).format("h:mm A");
    },
    allowedDates: function allowedDates(param) {
      if (param && moment(param).isValid()) {
        if (this.lodash.isEmpty(this.weeklyRecurrence) === false) {
          return this.lodash.includes(this.weeklyRecurrence.weeks, moment(param).day());
        }
      }

      return true;
    }
  },
  computed: {
    todayDate: function todayDate() {
      return moment().format("YYYY-MM-DD");
    },
    formattedStartDate: function formattedStartDate() {
      var Config = AppConfiguration.get();

      if (Config) {
        return moment(this.schedule.start_date).format(Config.dateFormat);
      }

      return this.schedule.start_date;
    },
    formattedEndDate: function formattedEndDate() {
      var Config = AppConfiguration.get();

      if (Config && this.schedule.end_date) {
        return moment(this.schedule.end_date).format(Config.dateFormat);
      }

      return this.schedule.end_date;
    }
  },
  beforeMount: function beforeMount() {
    if (!this.schedule.start_date) {
      this.schedule.start_date = new Date().toISOString().substr(0, 10);
    }
  },
  mounted: function mounted() {
    JobScheduleTeamEventBus.$emit("update:schedule-later", false);
  },
  beforeDestroy: function beforeDestroy() {
    /*JobScheduleTeamEventBus.$off("update:schedule-text");
    JobScheduleTeamEventBus.$off("update:schedule-date");
    JobScheduleTeamEventBus.$off("update:assigned-team");*/
  },
  created: function created() {
    var _this = this;

    if (_this.internal) {
      _this.schedule_later = 0;
    }

    JobScheduleTeamEventBus.$on("update:schedule-date", function (scheduleDate) {
      _this.schedule.start_date = scheduleDate;
    });
    JobScheduleTeamEventBus.$on("update:schedule-text", function (argument) {
      _this.scheduleString.start_date = moment(argument.start_date).format("DD MMMM YYYY");
      _this.scheduleString.end_date = moment(argument.end_date).format("DD MMMM YYYY");
      _this.scheduleString.start_time = moment(argument.start_time, ["HH:mm"]).format("hh:mm A");
      _this.scheduleString.end_time = moment(argument.end_time, ["HH:mm"]).format("hh:mm A");
    });
    /*JobScheduleTeamEventBus.$on("update:assigned-team", ({ assigned_team }) => {
      _this.schedule_later = 1;
      if (
        _this.lodash.isEmpty(assigned_team) === false &&
        assigned_team.length > 0
      ) {
        _this.schedule_later = 0;
      }
    });*/
  }
};