//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { TermConditionEventBus } from "@/core/lib/term.condition.lib";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
export default {
  name: "terms-conditions",
  data: function data() {
    return {
      timeoutLimit: 500,
      timeout: null,
      termsConditions: null
    };
  },
  components: {
    editor: TinyMCE
  },
  props: {
    pageLoading: {
      type: Boolean,
      required: true,
      default: false
    },
    updateData: {
      type: Object,
      default: function _default() {
        return new Object();
      }
    }
  },
  watch: {
    termsConditions: function termsConditions() {
      var _this = this;

      clearTimeout(_this.timeout);
      _this.timeout = setTimeout(function () {
        TermConditionEventBus.$emit("update:term-condition", _this.termsConditions);
      }, _this.timeoutLimit);
    },
    updateData: {
      deep: true,
      handler: function handler() {
        this.termsConditions = this.updateData.term_conditions;
      }
    }
  }
};