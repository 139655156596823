//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { NoteAttachmentEventBus } from "@/core/lib/note.attachment.lib";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
export default {
  name: "notes-attachment",
  data: function data() {
    return {
      timeoutLimit: 200,
      timeout: null,
      attachment: new Array(),
      notesAttachment: {
        admin_notes: null,
        client_notes: null,
        documents: new Array(),
        notify_admin: 0,
        notify_customer: 0,
        notify_engineer: 0
      }
    };
  },
  props: {
    disableNotification: {
      type: Boolean,
      default: false
    },
    pageLoading: {
      type: Boolean,
      required: true,
      default: false
    },
    isTicket: {
      type: Boolean,
      default: false
    },
    isPurchaseOrder: {
      type: Boolean,
      default: false
    },
    isProposal: {
      type: Boolean,
      default: false
    },
    updateData: {
      type: Object,
      default: function _default() {
        return new Object();
      }
    }
  },
  watch: {
    documents: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        this.notesAttachment.documents = param;
      }
    },
    notesAttachment: {
      deep: true,
      immediate: true,
      handler: function handler() {
        var _this = this;

        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          NoteAttachmentEventBus.$emit("update:notes-attachment", _this.notesAttachment);
        }, _this.timeoutLimit);
      }
    },
    updateData: {
      deep: true,
      immediate: true,
      handler: function handler() {
        this.setUpdateData();
      }
    }
  },
  methods: {
    updateFiles: function updateFiles(param) {
      this.notesAttachment.documents = param;
    },
    setUpdateData: function setUpdateData() {
      var _this = this;

      if (_this.lodash.isEmpty(_this.updateData) === false) {
        _this.notesAttachment = {
          admin_notes: _this.updateData.admin_remark,
          client_notes: _this.updateData.client_remark,
          documents: new Array(),
          notify_admin: +_this.updateData.notify_admin,
          notify_customer: +_this.updateData.notify_customer,
          notify_engineer: +_this.updateData.notify_engineer
        };
        _this.attachment = _this.updateData.documents ? _this.updateData.documents : new Array();
      }
    }
  },
  components: {
    FileTemplate: FileTemplate,
    editor: TinyMCE
  }
};