//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import OneOffScheduleHeader from "@/view/pages/job/partials/One-Off-Schedule-Header.vue";
import OneOffScheduleBody from "@/view/pages/job/partials/One-Off-Schedule-Body.vue";
import JobScheduleTeam from "@/view/pages/job/partials/Job-Schedule-Team.vue";
export default {
  name: "one-off-schedule",
  props: {
    showCalendar: {
      type: Boolean,
      default: false
    },
    internal: {
      type: Boolean,
      default: false
    },
    dailyRecurrence: {
      type: Object,
      default: function _default() {
        return new Object();
      }
    },
    monthlyRecurrence: {
      type: Object,
      default: function _default() {
        return new Object();
      }
    },
    weeklyRecurrence: {
      type: Object,
      default: function _default() {
        return new Object();
      }
    }
  },
  components: {
    OneOffScheduleHeader: OneOffScheduleHeader,
    OneOffScheduleBody: OneOffScheduleBody,
    JobScheduleTeam: JobScheduleTeam
  }
};