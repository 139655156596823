//
//
//
//
//
//
//
//
//
//
//
import JobScheduleTeamHeader from "@/view/pages/job/partials/Job-Schedule-Team-Header.vue";
import JobScheduleTeamBody from "@/view/pages/job/partials/Job-Schedule-Team-Body.vue";
export default {
  name: "job-schedule-team",
  components: {
    JobScheduleTeamHeader: JobScheduleTeamHeader,
    JobScheduleTeamBody: JobScheduleTeamBody
  }
};