import "core-js/modules/es.array.join.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { JobEventBus } from "@/core/lib/job/job.lib";
import { JobScheduleTeamEventBus } from "@/core/lib/job/job.schedule.team.lib";
import AppConfiguration from "@/core/config/app.config";
import RecurringPatternTemplate from "@/view/pages/job/partials/Recurring-Pattern-Template.vue";
import { QUERY } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog.vue";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
import Timepicker from "@/view/pages/partials/Timepicker.vue";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  name: "recurring-schedule-body",
  mixins: [CommonMixin, ValidationMixin],
  props: {
    showCalendar: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      dateDialog: false,
      dateValidateDialog: false,
      recurringLoading: false,
      schedule_later: 0,
      recurrenceMessage: null,
      startDatePicker: false,
      endDatePicker: false,
      rawStartTime: null,
      startTimePicker: false,
      rawEndTime: null,
      endTimePicker: false,
      schedule: {
        end_mode: 2,
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
        occurrence: 1,
        schedule_later: 0
      },
      responseSchedule: {
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null
      },
      timeOut: null,
      timeOutLimit: 500,
      RecurringType: null,
      RecurringPattern: {},
      RecurringSchedule: {},
      changed_visit_schedule: [],
      raw_visit_schedule: [],
      visit_schedule: []
    };
  },
  watch: {
    schedule: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        if (param.end_mode == 3) {
          if (param.end_date) {
            JobEventBus.$emit("update:recurring-schedule", param);
          }
        } else if (param.end_mode == 2) {
          var occurrence = this.lodash.toSafeInteger(param.occurrence);

          if (occurrence > 0) {
            JobEventBus.$emit("update:recurring-schedule", param);
          }
        } else {
          JobEventBus.$emit("update:recurring-schedule", param);
        }
      }
    },
    responseSchedule: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        var _this = this;

        if (_this.lodash.isEmpty(param) == false) {
          JobScheduleTeamEventBus.$emit("get:schedule-team", {
            start_date: param.start_date,
            end_date: param.end_date,
            start_time: param.start_time,
            end_time: param.end_time
          });
        }
      }
    },
    schedule_later: function schedule_later(param) {
      JobScheduleTeamEventBus.$emit("update:schedule-later", !!+param);
    }
  },
  components: {
    Dialog: Dialog,
    Datepicker: Datepicker,
    Timepicker: Timepicker,
    RecurringPatternTemplate: RecurringPatternTemplate
  },
  methods: {
    getDayName: function getDayName(date) {
      return moment(date).format("dddd");
    },
    getDuration: function getDuration() {
      var timing = false;
      var start_date_time = this.responseSchedule.start_date;

      if (this.responseSchedule.start_time) {
        timing = true;
        start_date_time = start_date_time + " " + this.responseSchedule.start_time;
      }

      var end_date_time = this.responseSchedule.end_date;

      if (this.responseSchedule.end_time) {
        timing = true;
        end_date_time = end_date_time + " " + this.responseSchedule.end_time;
      }

      var result = [];

      if (timing) {
        result.push(this.formatDateTime(start_date_time));
        result.push(this.formatDateTime(end_date_time));
      } else {
        result.push(this.formatDate(start_date_time));
        result.push(this.formatDate(end_date_time));
      }

      return result.join(" - ");
    },
    validateSchedule: function validateSchedule() {
      var _this2 = this;

      this.changed_visit_schedule = this.lodash.differenceWith(this.visit_schedule, this.raw_visit_schedule, this.lodash.isEqual);
      this.$nextTick(function () {
        _this2.dateDialog = false;
        _this2.dateValidateDialog = true;
      });
    },
    updateSchedule: function updateSchedule() {
      var _this3 = this;

      JobEventBus.$emit("update:recurring-dates", this.visit_schedule);
      this.$nextTick(function () {
        _this3.dateDialog = false;
        _this3.dateValidateDialog = false;
      });
    },
    updateStartTimeAMPM: function updateStartTimeAMPM(param) {
      var startTime = this.lodash.toString(this.schedule.start_time);

      if (startTime) {
        if (param == "am") {
          startTime = this.lodash.replace(startTime, "PM", "AM");
        } else {
          startTime = this.lodash.replace(startTime, "AM", "PM");
        }

        this.updateStartTime(moment(startTime, ["hh:mm A"]).format("HH:mm"));
      }
    },
    updateEndTimeAMPM: function updateEndTimeAMPM(param) {
      var endTime = this.lodash.toString(this.schedule.end_time);

      if (endTime) {
        if (param == "am") {
          endTime = this.lodash.replace(endTime, "PM", "AM");
        } else {
          endTime = this.lodash.replace(endTime, "AM", "PM");
        }

        this.updateEndTime(moment(endTime, ["hh:mm A"]).format("HH:mm"));
      }
    },
    updateStartTime: function updateStartTime(rawTime) {
      var _this4 = this;

      if (rawTime == "23:59") {
        rawTime = "00:00";
      }

      this.$nextTick(function () {
        _this4.rawStartTime = rawTime;

        _this4.$refs.rawStartTime.save(rawTime);

        _this4.schedule.start_time = moment(rawTime, ["HH:mm"]).format("h:mm A");
      });
    },
    updateEndTime: function updateEndTime(rawTime) {
      var _this5 = this;

      if (rawTime == "00:00") {
        rawTime = "23:59";
      }

      this.$nextTick(function () {
        _this5.rawEndTime = rawTime;

        _this5.$refs.rawEndTime.save(rawTime);

        _this5.schedule.end_time = moment(rawTime, ["HH:mm"]).format("h:mm A");
      });
    },
    getRecurrencePromise: function getRecurrencePromise(type) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          var start_time = _this.RecurringSchedule.start_time ? moment(_this.RecurringSchedule.start_time, ["hh:mm A"]).format("HH:mm") : null;
          var end_time = _this.RecurringSchedule.end_time ? moment(_this.RecurringSchedule.end_time, ["hh:mm A"]).format("HH:mm") : null;
          var recurrenceData = _this.RecurringPattern[type];
          var formData = new Object();

          switch (type) {
            case "daily":
              {
                formData = {
                  day_count: _this.lodash.toSafeInteger(recurrenceData.day_count),
                  occurrence: _this.lodash.toSafeInteger(_this.RecurringSchedule.occurrence),
                  start_date: _this.RecurringSchedule.start_date,
                  end_date: _this.RecurringSchedule.end_date,
                  recurring_pattern: _this.lodash.toSafeInteger(recurrenceData.recurring_pattern),
                  start_time: start_time,
                  end_time: end_time,
                  end_mode: _this.lodash.toSafeInteger(_this.RecurringSchedule.end_mode)
                };
                break;
              }

            case "weekly":
              {
                formData = {
                  weeks: _this.lodash.isArray(recurrenceData.weeks) ? recurrenceData.weeks.join(",") : null,
                  week_count: _this.lodash.toSafeInteger(recurrenceData.week_count),
                  occurrence: _this.lodash.toSafeInteger(_this.RecurringSchedule.occurrence),
                  start_time: start_time,
                  end_time: end_time,
                  start_date: _this.RecurringSchedule.start_date,
                  end_date: _this.RecurringSchedule.end_date,
                  end_mode: _this.lodash.toSafeInteger(_this.RecurringSchedule.end_mode)
                };
                break;
              }

            case "monthly":
              {
                formData = {
                  week_day: _this.lodash.toSafeInteger(recurrenceData.week_day),
                  day_count: _this.lodash.toSafeInteger(recurrenceData.day_count),
                  week_count: _this.lodash.toSafeInteger(recurrenceData.week_count),
                  month_count: _this.lodash.toSafeInteger(recurrenceData.recurring_pattern) === 1 ? _this.lodash.toSafeInteger(recurrenceData.day_month_count) : _this.lodash.toSafeInteger(recurrenceData.month_count),
                  recurring_pattern: _this.lodash.toSafeInteger(recurrenceData.recurring_pattern),
                  occurrence: _this.lodash.toSafeInteger(_this.RecurringSchedule.occurrence),
                  start_time: start_time,
                  end_time: end_time,
                  start_date: _this.RecurringSchedule.start_date,
                  end_date: _this.RecurringSchedule.end_date,
                  end_mode: _this.lodash.toSafeInteger(_this.RecurringSchedule.end_mode)
                };
                break;
              }
          }

          if (_this.lodash.isEmpty(recurrenceData) === false && _this.lodash.isEmpty(formData) === false && formData.start_date && moment(formData.start_date).isValid()) {
            _this.$store.dispatch(QUERY, {
              url: "job/recurrence/" + type,
              data: formData
            }).then(function (response) {
              resolve(response.data);
            }).catch(function (error) {
              reject(error);
            });
          } else {
            resolve({});
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getRecurrence: function getRecurrence() {
      var _this = this;

      clearTimeout(_this.timeOut);
      _this.recurringLoading = true;
      _this.RecurringType = _this.RecurringPattern.type;
      _this.recurrenceMessage = null;
      _this.timeOut = setTimeout(function () {
        _this.responseSchedule = new Object();
        _this.raw_visit_schedule = new Array();
        _this.visit_schedule = new Array();

        _this.getRecurrencePromise(_this.RecurringType).then(function (response) {
          if (_this.lodash.isEmpty(response) === false) {
            _this.responseSchedule = {
              start_date: response.start_date,
              end_date: response.end_date,
              start_time: response.start_time ? moment(response.start_time, ["HH:mm"]).format("h:mm A") : null,
              end_time: response.end_time ? moment(response.end_time, ["HH:mm"]).format("h:mm A") : null
            };
            _this.recurrenceMessage = response.message;

            if (response.dates && response.dates.length) {
              _this.visit_schedule = response.dates.map(function (row) {
                return {
                  status: 1,
                  is_edited: 0,
                  visit_date: row,
                  start_time: _this.responseSchedule.start_time,
                  end_time: _this.responseSchedule.end_time
                };
              });

              _this.$nextTick(function () {
                _this.raw_visit_schedule = _this.lodash.cloneDeep(_this.visit_schedule);
                JobEventBus.$emit("update:recurring-dates", _this.visit_schedule);
              });
            }
          }
        }).catch(function (error) {
          _this.logError(error);

          JobEventBus.$emit("update:recurring-dates", new Array());
          JobEventBus.$emit("update:recurring-schedule", new Object());
        }).finally(function () {
          _this.recurringLoading = false;
        });
      }, _this.timeOutLimit);
    }
  },
  computed: {
    dialogWidth: function dialogWidth() {
      return this.lodash.toSafeInteger(document.body.clientWidth / 100 * 60);
    },
    todayDate: function todayDate() {
      return moment().format("YYYY-MM-DD");
    },
    formattedStartDate: function formattedStartDate() {
      if (this.schedule.end_date && moment(this.schedule.start_date).isAfter(this.schedule.end_date)) {
        return null;
      }

      var Config = AppConfiguration.get();

      if (Config) {
        return moment(this.schedule.start_date).format(Config.dateFormat);
      }

      return this.schedule.start_date;
    },
    formattedEndDate: function formattedEndDate() {
      if (this.schedule.start_date && moment(this.schedule.end_date).isBefore(this.schedule.start_date)) {
        return null;
      }

      var Config = AppConfiguration.get();

      if (Config && this.schedule.end_date) {
        return moment(this.schedule.end_date).format(Config.dateFormat);
      }

      return this.schedule.end_date;
    }
  },
  beforeMount: function beforeMount() {
    if (!this.schedule.start_date) {
      this.schedule.start_date = new Date().toISOString().substr(0, 10);
    }
  },
  mounted: function mounted() {
    JobScheduleTeamEventBus.$emit("update:schedule-later", false);
  },
  beforeDestroy: function beforeDestroy() {
    /*JobEventBus.$off("update:scheduled-team");*/

    /*JobEventBus.$off("update:recurring-pattern");
    JobEventBus.$off("update:recurring-schedule");*/

    /*JobScheduleTeamEventBus.$off("update:assigned-team");*/

    /*JobEventBus.$off("update:recurring-pattern");
    JobEventBus.$off("update:recurring-schedule");*/
  },
  created: function created() {
    var _this = this;
    /*JobEventBus.$on("update:scheduled-team", argument => {
      _this.schedule.schedule_later = 1;
      if (
        _this.lodash.isEmpty(argument) === false &&
        argument.assigned_team.length > 0
      ) {
        _this.schedule.schedule_later = 0;
      }
    });*/


    JobEventBus.$on("update:recurring-pattern", function (argument) {
      _this.RecurringPattern = argument;

      _this.getRecurrence();
    });
    JobEventBus.$on("update:recurring-schedule", function (argument) {
      _this.RecurringSchedule = argument;

      _this.getRecurrence();
    });
    /*JobScheduleTeamEventBus.$on("update:assigned-team", ({ assigned_team }) => {
      _this.schedule_later = 1;
      if (
        _this.lodash.isEmpty(assigned_team) === false &&
        assigned_team.length > 0
      ) {
        _this.schedule_later = 0;
      }
    });*/
  }
};