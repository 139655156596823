//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { JobEventBus } from "@/core/lib/job/job.lib";
export default {
  name: "recurring-schedule-header",
  props: {
    showCalendar: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    updateShowCalendar: function updateShowCalendar() {
      JobEventBus.$emit("update:show-calendar");
    }
  }
};