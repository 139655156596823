import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { JobScheduleTeamEventBus } from "@/core/lib/job/job.schedule.team.lib";
import { ClearEventBus, ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { QUERY } from "@/core/services/store/request.module";
import moment from "moment-timezone";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  name: "job-schedule-team-header",
  data: function data() {
    return {
      scheduleLater: true,
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null,
      teamLoading: false,
      force: false,
      all: true,
      skills: [],
      assignedTeam: new Array(),
      availableTeam: new Array(),
      timeout: null,
      timeoutLimit: 1000
    };
  },
  watch: {
    assignedTeam: {
      deep: true,
      immediate: true,
      handler: function handler() {
        JobScheduleTeamEventBus.$emit("update:schedule-team", {
          availableTeam: this.availableTeam,
          assignedTeam: this.assignedTeam
        });
      }
    }
  },
  methods: {
    getAvailableTeam: function getAvailableTeam() {
      var _this = this;

      _this.availableTeam = [];
      _this.assignedTeam = [];

      if (_this.scheduleLater) {
        return false;
      }

      ClearEventBus.$emit("clear:success");

      if (moment(_this.startDate + " " + _this.startTime).isAfter(_this.endDate + " " + _this.endTime)) {
        ErrorEventBus.$emit("update:error", InitializeError("Start date & time must before End date & time."));
        return false;
      }

      if (!_this.all && _this.lodash.isEmpty(_this.skills)) {
        ErrorEventBus.$emit("update:error", InitializeError("Please select job type."));
        return false;
      }

      if (_this.lodash.isEmpty(_this.startDate)) {
        return false;
      }

      _this.teamLoading = true;

      _this.$store.dispatch(QUERY, {
        url: "job/available-engineers",
        data: {
          start_date: _this.startDate,
          start_time: _this.startTime,
          end_date: _this.endDate,
          end_time: _this.endTime,
          force: +_this.force,
          all: +_this.all,
          skills: _this.skills
        }
      }).then(function (_ref) {
        var data = _ref.data;
        _this.availableTeam = data.engineers;
        JobScheduleTeamEventBus.$emit("update:schedule-text", data);
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.teamLoading = false;
      });
    }
  },
  beforeDestroy: function beforeDestroy() {
    /*JobScheduleTeamEventBus.$off("get:schedule-team");
    JobScheduleTeamEventBus.$off("update:schedule-later");
    JobScheduleTeamEventBus.$off("remove:schedule-team");
    JobScheduleTeamEventBus.$off("update:schedule-later");
    JobScheduleTeamEventBus.$off("update:skills");*/
  },
  mounted: function mounted() {
    JobScheduleTeamEventBus.$emit("get:skills");
  },
  created: function created() {
    var _this = this;

    JobScheduleTeamEventBus.$on("get:schedule-team", function (param) {
      try {
        _this.startDate = param.start_date ? moment(param.start_date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
        _this.startTime = param.start_time ? moment(param.start_time, ["h:mm A"]).format("HH:mm") : moment().startOf("day").format("HH:mm");
        _this.endDate = param.end_date ? moment(param.end_date).format("YYYY-MM-DD") : moment(_this.startDate).format("YYYY-MM-DD");
        _this.endTime = param.end_time ? moment(param.end_time, ["h:mm A"]).format("HH:mm") : moment().endOf("day").format("HH:mm");
        _this.availableTeam = new Array();
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.getAvailableTeam();
        }, _this.timeoutLimit);

        if (param.schedule_later === 1) {
          _this.assignedTeam = new Array();
        }
      } catch (error) {
        _this.logError(error);
      }
    });
    JobScheduleTeamEventBus.$on("remove:schedule-team", function (_ref2) {
      var index = _ref2.index;

      _this.assignedTeam.splice(index, 1);
    });
    JobScheduleTeamEventBus.$on("update:schedule-later", function (param) {
      if (param) {
        _this.assignedTeam = new Array();
      }

      _this.scheduleLater = param;

      if (_this.scheduleLater) {
        _this.availableTeam = [];
        _this.assignedTeam = [];
      }
    });
    JobScheduleTeamEventBus.$on("update:skills", function (param) {
      if (_this.lodash.isEmpty(param) === false) {
        _this.all = false;
      } else {
        _this.all = true;
      }

      _this.skills = param;

      _this.getAvailableTeam();
    });
  }
};