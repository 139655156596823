import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ValidationMixin from "@/core/plugins/validation-mixin";
import JobRecurringPatternMixin from "@/core/lib/job/job.recurring.pattern.mixin";
import { JobEventBus } from "@/core/lib/job/job.lib";
export default {
  name: "recurring-pattern-template",
  mixins: [JobRecurringPatternMixin, ValidationMixin],
  props: {
    showCalendar: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      timeout: null,
      timeoutLimit: 500,
      pattern: {
        type: "daily",
        daily: {
          day_count: "1",
          recurring_pattern: "2"
        },
        weekly: {
          recurring_pattern: "1",
          week_count: "1",
          weeks: [1]
        },
        monthly: {
          recurring_pattern: "1",
          day_count: "1",
          week_day: 1,
          week_count: 1,
          day_month_count: "1",
          month_count: "1"
        }
      }
    };
  },
  watch: {
    "pattern.type": function patternType(param) {
      var _this = this;

      if (param.toString() === "daily") {
        _this.pattern.daily.day_count = "1";
        _this.pattern.daily.recurring_pattern = "2";
      }

      if (param.toString() === "weekly") {
        _this.pattern.weekly.week_count = "1";
        _this.pattern.weekly.weeks = [1];
        _this.pattern.weekly.recurring_pattern = "2";
      }

      if (param.toString() === "monthly") {
        _this.pattern.monthly.recurring_pattern = "1";
        _this.pattern.monthly.day_count = "1";
        _this.pattern.monthly.week_day = 1;
        _this.pattern.monthly.week_count = 1;
        _this.pattern.monthly.day_month_count = "1";
        _this.pattern.monthly.month_count = "1";
      }
    },
    pattern: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        var _this2 = this;

        var pattern = this.lodash.cloneDeep(param);

        if (pattern.type == "daily") {
          if (pattern.daily.recurring_pattern == 1) {
            pattern.daily.day_count = 0;
          }
        }

        this.$nextTick(function () {
          _this2.emitEvent();
        });
      }
    }
  },
  methods: {
    emitEvent: function emitEvent() {
      var _this = this;

      clearTimeout(_this.timeout);
      _this.timeout = setTimeout(function () {
        JobEventBus.$emit("update:recurring-pattern", _this.pattern);
      }, _this.timeoutLimit);
    }
  },
  computed: {
    verticalScrollbar: function verticalScrollbar() {
      return "max-width: " + (this.showCalendar ? "60vh;" : "100%;");
    }
  }
};